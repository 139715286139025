<template>
  <div v-if="!isIframe">
    <!-- 未认证或者未授权 -->
    <div class="authorization-box" v-if="isPass && isShow">
      <div class="authorization-center">
        <img :src="[isAuthentication ? authentication : accredit]" alt="" />
        <div v-if="isAuthentication" class="marked-words">您还未进行企业认证，请前往认证</div>
        <div v-else class="marked-words">您还未授权电子签约功能，请前往授权！</div>
        <div v-if="isAuthentication" class="opera-box">
          <a-button class="form-btn" @click="overAuthentication">我已认证</a-button>
          <a-button class="kernel-btn" @click="goAuthentication">前往认证</a-button>
        </div>
        <div v-else class="opera-boxs">
          <a-button class="kernel-btns" @click="goAccredit">前往授权</a-button>
        </div>
      </div>
    </div>

    <!-- 认证授权成功的页面 -->
    <div class="seal-box" v-if="!isPass">
      <div class="seal-tabs">
        <!-- 企业信息 -->
        <div class="message-box">
          <div class="enterprise-box">
            <img class="enterprise-img" src="@/assets/images/eleSeal/enterprise-img.png" alt="" />
            <img class="authentication-icon" src="@/assets/images/eleSeal/authentication-icon.png" alt="" />
          </div>
          <div class="detail-box">
            <div class="enterprise-title">{{ userInfo.enterpriseName }}</div>
            <div class="legal-person">法人代表：{{ userInfo.legalPerson }}</div>
            <div class="credit-code">统一社会信用代码：{{ userInfo.corpCode }}</div>
          </div>
        </div>
        <!-- 电子章信息 -->
        <div class="seal-message">
          <div class="seal-center">
            <div class="left-context">
              <div class="seal-length">电子印章剩余使用次数</div>
              <div class="length-box">{{ userInfo.electronicSealCount }}<span>次</span></div>
            </div>
            <a-button type="primary" @click="topUp">充值</a-button>
          </div>
        </div>
      </div>

      <!-- 表格 -->
      <!-- 内容 -->
      <section style="margin: 24px" class="table-style">
        <div class="table-head">
          <span class="title">文件列表</span>
        </div>
        <a-table
          :columns="Columns"
          :dataSource="dataSource"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="tableLoading"
          rowKey="id"
          ref="tableRef"
          :scroll="{ x: 400 }"
        >
          <!-- 序号 -->
          <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
          <!-- 接收方 -->
          <template #signatory="{text}">
            <span :title="text">{{ text ? text : '-' }}</span>
          </template>
          <!-- 签署状态 -->
          <template #status="{text}">
            <span :style="{ background: signStatus[text]?.color }" class="status-icon"></span>
            <span>{{ signStatus[text]?.status }}</span>
          </template>
          <!-- 操作 -->
          <template #action="{record}">
            <div class="action-box">
              <span @click="sign(record)">签署</span>
              <span @click="fileDetail(record.id)">详情</span>
              <span @click="downloadItems(record)">下载</span>
            </div>
          </template>
        </a-table>
        <drawer :visible="visible" :current="current" @close="drawerClose" @goIframe="getIframe" is />
      </section>
    </div>
  </div>

  <div v-if="isIframe" style="width: 100%; height: 20px;"></div>
  <div style="height: 100%; position: relative;" v-if="isIframe">
    <div
      style="width: 80px;height: 50px;opacity: 0;cursor:pointer;position: absolute;top: 0px;left: 15px;border: 0;display: flex;align-items: center;"
      @click="closeIframe"
    >
      <img
        style="width: 14px; height: 14px;margin: 0px 0 0 10px;cursor:pointer;"
        src="@/assets/images/eleSeal/return-img.png"
        alt=""
      />
    </div>
    <div class="close-box">
      <div class="cloce-title">点击关闭签署</div>
      <a-button @click="closeIframe">关闭</a-button>
    </div>
    <iframe width="90%" height="90%" :src="url"></iframe>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs, h, createVNode } from 'vue'
import authentication from '@/assets/images/eleSeal/authentication-img.png'
import accredit from '@/assets/images/eleSeal/accredit-img.png'
import { notification } from 'ant-design-vue'
import {
  authenticationUrl,
  accreditUrl,
  jurisdiction,
  enterpriseMessage,
  signUrl,
  downloadItem
} from '@/apis/eleSeal.js'
import { Modal } from 'ant-design-vue'
import { Columns } from '../fileManage/columns.js'
import drawer from '../components/drawer.vue'
import { fileList } from '@/apis/eleSeal.js'
import { paginationParams, signStatus } from '@/utils/constData'
import { ExclamationOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    drawer,
    ExclamationOutlined
  },
  setup() {
    // 核心数据
    const state = reactive({
      isPass: true, // 是否通过认证和授权
      isAuthentication: true, // 是否认证页面
      isShow: false,
      Columns,
      dataSource: [], // 请求的数据
      pagination: {
        ...paginationParams
      },
      currentNum: 1,
      loading: false,
      visible: false, // 详情抽屉开关
      current: undefined, // 弹框数据
      tableLoading: true, // 加载loading
      isIframe: false, // 是否展示iframe
      userInfo: {}, // 企业信息数据
      url: '',
      currentItem: {} // 当前选择
    })

    // 前往认证
    const goAuthentication = async () => {
      const res = await authenticationUrl()
      if (res.data) {
        window.open(res.data)
      }
    }

    // 我已认证
    const overAuthentication = async () => {
      const res = await jurisdiction()
      console.log('res', res)
      if (res.code === 0) {
        if (res.data.privilegeStatus) {
          state.isPass = false
          return
        }
        if (res.data.authStatus === 1) {
          state.isAuthentication = !res.data.authStatus
          notification['success']({
            message: '提示',
            description: '认证成功'
          })
          goAccredit()
        } else if (res.data.authStatus === 2) {
          notification['error']({
            message: '提示',
            description: '认证未通过，请重新认证'
          })
        } else if (res.data.authStatus === 0) {
          notification['error']({
            message: '提示',
            description: '企业认证中，请耐心等待'
          })
        } else if (res.data.authStatus === -1) {
          notification['error']({
            message: '提示',
            description: '企业未认证，请前往认证'
          })
        }
      }
    }

    // 前往授权
    const goAccredit = async () => {
      const res = await accreditUrl('')
      if (res.data) {
        window.open(res.data)
        let timer = setInterval(async () => {
          const res = await jurisdiction()
          if (res.code === 0) {
            if (res.data.privilegeStatus) {
              state.isPass = false
              clearInterval(timer)
              notification['success']({
                message: '提示',
                description: '授权成功'
              })
              getList()
              getEnterpriseMessage()
              return
            }
          }
        }, 3000)
      }
    }

    // 获取当前用户权限
    const getJurisdiction = async () => {
      const res = await jurisdiction()
      if (res.code === 0) {
        if (res.data.privilegeStatus) {
          state.isPass = false
          getList()
          getEnterpriseMessage()
          return
        }
        state.isAuthentication = res.data.authStatus != 1
        setTimeout(() => {
          state.isShow = true
        }, 100)
      }
    }

    // 充值按钮
    const topUp = () => {
      Modal.confirm({
        title: '充值提示',
        icon: createVNode(ExclamationOutlined),
        okText: '我知道了',
        cancelText: '',
        centered: true,
        content: h('div', {}, [
          h('p', '请联系客服'),
          h('span', '联系方式：'),
          h('a', { style: 'color:#1890FF;' }, '400-032-2669')
        ]),
        onOk() {
          console.log('ok')
        }
      })
    }

    // 签署
    const sign = async item => {
      const res = await signUrl({
        id: item.id,
        url: { callbackPage: `${process.env.VUE_APP_PC_BASE_URL}/signedInstrument` }
      })
      if (res.code === 0) {
        state.url = res.data
        state.isIframe = true
      }
    }
    // 关闭iframe
    const closeIframe = () => {
      state.isIframe = false
      getList()
    }
    // 详情
    const fileDetail = id => {
      console.log('详情id', id)
      state.visible = true
      state.current = id
    }
    // 抽屉关闭
    const drawerClose = () => {
      state.visible = false
    }
    // 列表下载
    const downloadItems = async item => {
      console.log('下载id', item)
      const res = await downloadItem({ id: item.id, fileType: 1 })
      const link = document.createElement('a')
      let fileName = decodeURIComponent(res.headers['content-disposition'].split('=')[1]) //拆解获取文件名，
      // let blob = new Blob([res], { type: "application/zip" });  //文件流处理
      let blob = res.data //文件流处理
      link.style.display = 'none' //去除a标签的样式设置连接
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      document.body.appendChild(link)
      // 模拟点击事件
      link.click()
    }

    // 获取子组件的iframe
    const getIframe = id => {
      console.log('id', id)
      sign(id)
    }

    // 列表数据
    const getList = async () => {
      state.tableLoading = true
      const res = await fileList({
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        status: 1,
        search: '',
        startDate: '',
        endDate: ''
      })
      if (res.code === 0) {
        state.dataSource = res.data
        state.pagination.total = res.totalItem
        state.pagination.index = res.pageIndex
      }
      console.log('res', res)
      // 结束后
      state.tableLoading = false
    }

    // 换页
    const handleTableChange = ({ current, pageSize }) => {
      state.dataSource = []
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      getList()
    }

    // 获取企业信息
    const getEnterpriseMessage = async () => {
      const res = await enterpriseMessage()
      console.log('企业信息', res)
      if (res.success) {
        state.userInfo = res.data
      }
    }

    onMounted(() => {
      getJurisdiction()
    })
    return {
      goAuthentication,
      overAuthentication,
      goAccredit,
      ...toRefs(state),
      authentication,
      accredit,
      topUp,
      handleTableChange,
      signStatus,
      sign,
      closeIframe,
      fileDetail,
      drawerClose,
      downloadItems,
      getIframe
    }
  }
}
</script>

<style lang="less" scoped>
html,
body {
  height: 100%;
}
iframe {
  display: block;
  border: none;
  height: calc(100vh - 150px);
  width: calc(100vw - 208px);
}
.charts-icon {
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}

.dottos {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}
.seal-list {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

// 为认证未授权
.authorization-box {
  width: 100%;
  height: 216px;
  margin-top: 139px;

  .authorization-center {
    width: 314px;
    height: 100%;
    margin: auto;

    img {
      width: 314px;
      height: 152px;
    }

    .marked-words {
      height: 24px;
      font-size: 16px;
      font-weight: 400;
      color: #646566;
      line-height: 24px;
      text-align: center;
      margin-bottom: 10px;
    }

    // 未认证
    .opera-box {
      width: 196px;
      margin: auto;
      .form-btn {
        margin-right: 16px;
      }
      .kernel-btn {
        color: #ffffff;
        background-color: #c3161c;
        border: none;
        border-radius: 2px;
      }
    }
    // 未授权
    .opera-boxs {
      width: 88px;
      margin: auto;
      .kernel-btns {
        color: #ffffff;
        background-color: #c3161c;
        border: none;
        border-radius: 2px;
      }
    }
  }
}

// 内容部分
.seal-tabs {
  width: 100%;
  height: 117px;
  margin-top: -36px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;

  // 企业信息
  .message-box {
    width: 627px;
    height: 100%;
    display: flex;
    align-items: center;

    // 图片
    .enterprise-box {
      width: 85px;
      height: 85px;
      position: relative;
      margin: 24px;
      .enterprise-img {
        width: 85px;
        height: 85px;
      }
      .authentication-icon {
        width: 30px;
        height: 32px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .detail-box {
      width: 510px;
      height: 76px;

      .enterprise-title {
        height: 24px;
        font-size: 16px;
        font-weight: 600;
        color: #1a1a1b;
        line-height: 24px;
      }
      .legal-person {
        height: 22px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        margin: 6px 0 2px 0;
      }
      .credit-code {
        height: 22px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
      }
    }
  }

  // 印章信息
  .seal-message {
    width: 302px;
    height: 100%;
    display: flex;
    align-items: center;

    .seal-center {
      width: 253px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-context {
        width: 148px;
        height: 100%;
        .seal-length {
          height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }
        .length-box {
          height: 32px;
          font-size: 24px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 32px;

          span {
            display: inline-block;
            height: 22px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
            margin-left: 6px;
          }
        }
      }
    }
  }
}

.action-box {
  color: #1890ff;
  display: flex;
  justify-content: flex-end;

  span {
    margin: 0 8px 0 8px;
    cursor: pointer;
  }
}

// 签署状态
.status-icon {
  width: 6px;
  height: 6px;
  display: inline-block;
  margin: 0 8px 2px 0;
  border-radius: 3px;
}

.close-box {
  position: absolute;
  top: -35px;
  right: 24px;
  display: flex;
  align-items: center;
  .cloce-title {
    height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    margin-right: 8px;
  }
}
</style>
